@import "./../../Utils/colors";

.wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
    img {
        width: 50%;
        object-fit: cover;
    }
}

.noMailContentWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div:nth-child(2) {
        font-weight: 600;
        font-size: 0.9em;
        margin: 20px 0 10px 0;
    }

    div:nth-child(3) {
        font-weight: 400;
        font-size: 0.8em;
    }
}

.replyWrapper {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    .smallText {
        padding-left: 0.5rem;
        font-size: 0.8em;
        font-weight: 400;
        color: $dark-grey;
    }

    .replyHeader {
        color: $dark-grey;
        font-size: 0.8rem;
        font-weight: 400;
        margin: 0.5rem 0 0 0;
        padding: 0.5rem 0 0.5rem 0;
        border-bottom: 3px solid $header-blue;
        width: 3.7rem;
    }
    .replyTextarea {
        width: 100%;
        height: 10rem;
        border: 1px solid $cloud;
        margin-top: 0.5rem;
        padding: 0.5rem;
        resize: none;
    }
    .replyFooter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .replyButton {
        background-color: $header-blue;
        color: $white;
        border: none;
        padding: 0.5rem 1rem;
        margin: 0.5rem 1rem;
        border-radius: 5px;
        width: 6rem;
        font-size: 0.8rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            background-color: $medium-blue;
        }
    }

}

.mainBody {
    padding: 1rem;
    .profileHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $cloud;
        padding-bottom: 1rem;

        .outerFlex{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .innerFlex{
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                margin-left: 1rem;
                justify-content: space-around;

                span:nth-child(1){
                    font-size: 0.9em;
                    font-weight: 300;
                };

                span:nth-child(2){
                    font-weight: 600;
                }
            }
        }
    }
    .profileIconStyles {
        color: $cloud;
    }
    hr {
        border: none;
        height: 1px;
        background-color: $cloud;
    }
    main {
        .dangerousDiv {
            white-space: pre-line;
            font-size: 0.9em;
            padding-bottom: 2rem;
            border-bottom: 1px solid $cloud;
        }
    }
}

input[type="file"] {
    display: none;
}
.attachment {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.button {
    background-color: $header-blue;
    color: $white;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem 1rem;
    border-radius: 5px;
    width: 8rem;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: $medium-blue;
    }
}

.message {
    border-bottom: 1px solid $cloud;
    padding-top: 1rem;
}

.messageHeader {
    padding-bottom: 1rem;
}

.messageHeaderItem {
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
    font-weight: 400;
}

.bold {
    font-weight: 600;
    margin-right: 1rem;
}

.actionRequired {
    color: $header-blue;
    font-size: 0.8rem;
    font-weight: 600;
}

.scrollable {
    overflow-y: auto;
    height: 60%;
}

.deleteIconStyles {
    color: $dark-grey;
    cursor: pointer;

    &:hover {
        color: $header-blue;
    }
}

.right {
    float: right;
}