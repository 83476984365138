$white : #fff;
$mist : #f2f3f7;
$header-blue : #2f86dd;
$medium-blue : rgb(34, 59, 155);
$dark-blue : #2c3e50;
$yellow : #f9ca24;
$dark-grey : #535c68;
$medium-grey : #ccc;
$cloud : lighten($medium-grey, 10%);
$sky-green : #55efc4;
$light-red : #e74c3c;
$dark-red : #c0392b;