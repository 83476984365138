@import './Utils/resuables';

@font-face {
  font-family: "openSans";
  src: url('./Assets/Fonts/OpenSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: "openSans";
  src: url('./Assets/Fonts/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "openSans";
  src: url('./Assets/Fonts/OpenSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: "openSans";
  src: url('./Assets/Fonts/OpenSans-Bold.ttf');
  font-weight: 700;
}

body {
  margin: 0;
  padding : 0;
  overflow-y: hidden;
  overflow-x: auto;
  @include customScrollBar;
}

*{
  box-sizing: border-box;
  // font-family: "openSans", sans-serif;
  font-weight: 400;
}

jt-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

