@import './../../Utils/colors';

.wrapper {
    background-color: $white;
    font-size : 20px;
    font-weight: 200;
    color : $dark-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    padding: 12px 20px;
    border-bottom: 1px solid #e9ecef;
}

.titleStyles{
    font-weight: 500;
    font-size: 1.3rem;
    margin-left: 1rem;
}

.iconStyles {
    color: $yellow;
}

img {
    width: 38px;
    height: 38px;
}

.searchButton {
    background-color: $header-blue;
    color: $white;
    border: none;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 0.5rem;
    border-radius: 5px;
    width: 8rem;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: $medium-blue;
    }
}

.searchInput {
    padding: 0.5rem 0.5rem;
    margin: 0.5rem 0.25rem;
    border-radius: 5px;
    width: 15rem;
    font-size: 0.8rem;
    border: 1px solid #e6e6e6;
}

.clearText {
    font-size: 0.8rem;
    color: $header-blue;
    float: right;
    margin: 0rem 0.6rem;
    cursor: pointer;
    &:hover {
        color: $medium-blue;
    }
}

.appName {
    line-height: 1;
    margin-bottom: 0;
    padding-left: 12px;
}