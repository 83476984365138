// mixins

@mixin textOverflow {
    overflow: hidden;
    word-wrap: none;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin customScrollBar {
    /* width */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
