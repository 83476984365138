*:focus {
    outline: none;
}

.background {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.root-wrapper {
    background-color: #ffffff;
    display: grid !important;
    height: 65%;
    width: 100%;
    grid-template-rows: 100px calc(100vh - 366px);
    grid-template-columns: 25% 75% auto;
    grid-template-areas: "HEADER HEADER"
                        "MAILLIST MAILBODY";
}

.root-header {
    grid-area: HEADER;
}

.root-maillist{
    grid-area: MAILLIST;
}

.root-mailbody{
    grid-area: MAILBODY;
    // height: calc(100vh - 370px) !important;
    height: calc(57vh) !important;
    overflow: hidden;
    overflow-y: auto;
}

.new-message-modal {
    position: absolute;
    top: 112px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 2rem;
}

.new-message-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f2f3f7;
}
.replyWrapper {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
}
.smallText {
    padding-left: 0.5rem;
    font-size: 0.8em;
    font-weight: 400;
    color: #535c68;
}

.replyHeader {
    color: #535c68;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 1rem 0 0.5rem 0;
    padding: 1rem 0 0.5rem 0;
    border-bottom: 3px solid #3086dd;
    width: 3.5rem;
}
.replyTextarea {
    width: 100%;
    height: 10rem;
    border: 1px solid #dddddd;
    margin-top: 0.5rem;
    padding: 0.5rem;
    resize: none;
}

.toTextarea {
    width: 100%;
    border: 1px solid #dddddd;
    margin-top: 0.5rem;
    padding: 0.5rem;
    resize: none;
}

.replyFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.replyButton {
    background-color: #3086dd;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.5rem 1rem;
    border-radius: 5px;
    width: 6rem;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: rgb(34, 59, 155);
    }
}

.wrapper {
    background-color: #ffffff;
    font-size : 20px;
    font-weight: 200;
    color : #535c68;
    border-top: #ffffff 1px solid;
    border-radius: 8px 8px 0 0;
}

.titleStyles {
    padding-left: 1rem;
    font-weight: 400;
}

.breadcrumbs {
    font-size: 0.8rem;
    color: #535c68;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

.link {
    font-size: 0.8rem;
    color: #3086dd;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
input[type="file"] {
    display: none;
}
.attachment {
    display: inline-block;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.hack {
    position: absolute;
    top: 90px;
    right: 70px;
    background: transparent;
    border: none !important;
    font-size:0;
    width: 150px;
    height: 30px;
}